import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import { yiluTracking } from 'utils/index';

import { ParkingProductSummaryProps } from 'modules/parking/components/ParkingProductSummary/types';
import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';

export function useParkingProductSummaryProps(
  currentParking: SearchItem | undefined,
  paymentFormRef: React.RefObject<HTMLFormElement>,
) {
  const { t } = useTranslation();

  const parkingProductSummaryProps = useMemo(() => {
    if (!currentParking) {
      return null;
    }

    const parkingDetails = getParkingDetails(currentParking);
    const { latitude, longitude } = parkingDetails;
    const timezone = latitude && longitude ? tzlookup(latitude, longitude) : undefined;

    const _props: ParkingProductSummaryProps = {
      price: parkingDetails.price,
      currency: parkingDetails.currency,
      enterDate: UiUtilities.formatLocalDate(parkingDetails.arrival, 'YYYY-MM-DD', timezone),
      enterTime: UiUtilities.formatLocalDate(parkingDetails.arrival, 'HH:mm', timezone),
      exitDate: UiUtilities.formatLocalDate(parkingDetails.departure, 'YYYY-MM-DD', timezone),
      exitTime: UiUtilities.formatLocalDate(parkingDetails.departure, 'HH:mm', timezone),
      parkingName: parkingDetails.name,
      termsDetails: {
        termsUrl: parkingDetails.policy.termsUrl,
        productDetails: {
          providerId: parkingDetails.providerId,
          locationName: parkingDetails.address,
        },
      },
      onClick() {
        if (paymentFormRef && paymentFormRef.current) {
          const paymentForm = paymentFormRef.current;
          paymentForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          yiluTracking.sendGAEvent({
            event: 'pip_cta_clicked',
            category: 'parking-pip',
            label: 'parking_pip_cta_clicked',
          });
        }
      },
      isDisabled: false,
      submitBtnLabel: t('Pay now'),
      address: parkingDetails.address,
    };
    return _props;
  }, [paymentFormRef, currentParking, t]);

  return parkingProductSummaryProps;
}
